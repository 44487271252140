import { connect } from 'react-redux';

import {mapDispatchToProps,mapStateToProps, PopupContainer as SourcePopupContainer} from 'SourceComponent/Popup/Popup.container';
import { noopFn } from 'Util/Common';


/** @namespace Ennero/Component/Popup/Container */
export class PopupContainer extends SourcePopupContainer {
    static defaultProps ={
        onVisible: noopFn,
        onClose: noopFn,
        onHide: noopFn,
        mix: {},
        contentMix: {},
        children: [],
        isStatic: false,
        isCloseOnOutsideClick: true,
        isHistoryBackHandle: true,
        classAnimationIn: 'animate__slideInRight',
        showContent: false,
        isLeftNav: false
    };

    

    containerProps() {
        const {
            isCloseOnOutsideClick,
            activeOverlay,
            areOtherOverlaysOpen,
            changeHeaderState,
            children,
            id,
            isMobile,
            isStatic,
            mix,
            contentMix,
            onClose,
            onHide,
            shouldPopupClose,
            hideActiveOverlay,
            resetHideActivePopup,
            goToPreviousNavigationState,
            isHistoryBackHandle,
            classAnimationIn,
            showContent,
            isLeftNav
        } = this.props;

        return {
            isCloseOnOutsideClick,
            activeOverlay,
            areOtherOverlaysOpen,
            changeHeaderState,
            children,
            id,
            isMobile,
            isStatic,
            mix,
            contentMix,
            shouldPopupClose,
            onClose,
            onHide,
            hideActiveOverlay,
            resetHideActivePopup,
            goToPreviousNavigationState,
            isHistoryBackHandle,
            classAnimationIn,
            showContent,
            title: this._getPopupTitle(),
            isLeftNav
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
