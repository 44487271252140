
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/** @namespace Ennero/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {


containerProps() {
    const {
        events,
        validateOn,
        type,
        attr,
        isDisabled,
        mix,
        value,
        options,
        showErrorAsLabel,
        label,
        subLabel,
        addRequiredTag,
        changeValueOnDoubleClick,
        isSortSelect,
        placeHolder
    } = this.props;
    const { validationResponse } = this.state;
    const { validate } = this.containerFunctions;

    // Surrounds events with validation
    const newEvents = { ...events };

    validateOn.forEach((eventName) => {
        const { [ eventName]: baseEvent } = events;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newEvents[ eventName ] = baseEvent
            ? this.validateOnEvent.bind(this, baseEvent) : validate;
    });

    return {
        type,
        attr,
        value,
        isDisabled,
        mix,
        options,
        showErrorAsLabel,
        label,
        subLabel,
        addRequiredTag,
        changeValueOnDoubleClick,
        isSortSelect,
        validationResponse,
        resetFieldValue: this.resetFieldValue.bind(this),
        events: newEvents,
        setRef: this.setRef.bind(this),
        placeHolder
    };
}

}

export default FieldContainer;